.verifyTop {
    position: relative;
    min-height: 100vh;
    height: auto;
    background-image: url("./../../img/Bg.png");
    background-position: 100% 100%;
    background-size: cover;
    width: 100%;
  }
  .verifyTop .mainContent {
    min-height: 78vh;
    height: auto;
    width: 90%;
    background-color: #242424;
    padding: 30px;
    border-radius: 20px;
    margin: auto;
  }
  .verifyTop .mainContent .headerPolicy {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
  }
  .verifyTop .mainContent h3 {
    color: var(--White, #fff);
    font-size: 24px;
    font-style: normal;
    margin-bottom: 16px;
    font-weight: 700;
    line-height: 130%; /* 31.2px */
  }
  .verifyTop .mainContent p {
    color: var(--White, #fff);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%; 
    margin-bottom: 24px;
  }
  .verifyTop .mainContent .headerPolicy img {
    margin-right: 10px;
  }
  .verifyTop .mainContent .headerPolicy h2 {
    color: #fff;
  }
  .footer {
    bottom: 0px;
    left: 5%;
    right: 5%;
    margin: auto;
    width: 90%;
    margin: auto;
    padding: 30px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .footer p {
    color: #fff;
  }
  .footer a {
    color: #fff;
    text-decoration: none;
  }
  .footer a:hover {
    text-decoration: underline;
  }
  
  .footer ul {
    display: flex;
    align-items: center;
    list-style-type: none;
  }
  footer ul li {
    list-style-type: none;
  }
  .footer ul li:nth-child(1) {
    list-style-type: none;
    margin-right: 10px;
  }
  .footer ul li:nth-child(2) {
    list-style-type: none;
  }
  
  @media only screen and (max-width: 600px) {
    .footer {
      flex-direction: column;
      justify-content: center;
    }
    .footer p {
      margin-bottom: 15px;
    }
  }
  