.headerTop{
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 5%;
}
.headerTop img{
    width: 200px;
}
.headerTop button{
    background: transparent;
    padding: 10px 30px;
    border-radius: 5px;
    border: 1px solid #FF6768;
    cursor: pointer;
    box-shadow: none;
    outline: none;
    color: #fff;
    transition: 0.4s;
}
.headerTop button:hover{
    background: #FF6768;
    color: #fff;
}
@media only screen and (max-width:540px){
    .headerTop img{
        width: 150px;
    }  
    .headerTop button{
        padding:5px 10px;
    }
}