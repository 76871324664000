.verifyTop {
  position: relative;
  min-height: 100vh;
  background-image: url("./../../img/Bg.png");
  background-position: 100% 100%;
  background-size: cover;
  width: 100%;
}
.verifyTop .imgRight {
  position: absolute;
  top: 15%;
  right: 0px;
}
.verifyTop .imgLeft {
  position: absolute;
  top: 40%;
  left: 0px;
}
.verifyTop .heroTop {
  width: 100%;
  min-height: 78vh;
  height: auto;
  padding-top: 40px;
}
.verifyTop .heroTop .innerContent {
  align-items: center;
  justify-content: center;
  text-align: center;
}
.verifyTop .heroTop .innerContent h1 {
  font-size: 7vw;
  margin-bottom: 20px;
  color: #fff;
}

.verifyTop .heroTop .innerContent p {
  font-size: 25px;
  margin-bottom: 30px;
  color: #fff;
}
.verifyTop .heroTop .innerContent a {
  background: transparent;
  padding: 12px 40px;
  border-radius: 5px;
  cursor: pointer;
  max-width: 400px;
  width: 90%;
  color: #fff;
  transition: 0.4s;
  border: 1px solid #ff6768;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  box-shadow: none;
  margin-bottom: 20px;
  outline: none;
  text-decoration: none;
}
.signMessage {
  background: transparent;
  padding: 12px 40px;
  border-radius: 5px;
  cursor: pointer;
  color: #fff;
  transition: 0.4s;
  border: 1px solid #ff6768;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  box-shadow: none;
  margin-bottom: 20px;
  outline: none;
  text-decoration: none;
}
.verifyTop .heroTop .innerContent a svg {
  margin-right: 20px;
  width: 30px;
  height: 30px;
}
.verifyTop .heroTop .innerContent a:hover,
.signMessage:hover {
  background: #ff6768;
  color: #fff;
}
.signMessage {
  margin: 20px auto;
}
.toggle {
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 10px;
  padding-bottom: 5;
}
.toggle label {
  padding: 25px;
}
.walletVerified h3 {
  text-align: center;
  color: #fff;
  margin: 20px auto;
}

/* discordLogin  ============= */
.discordLogin {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.discordLogin .profilePic {
  display: flex;
  align-items: center;
  margin: 20px 0px;
}
.discordLogin .profilePic img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}
.discordLogin h1 {
  font-size: 3vw;
  margin-bottom: 20px;
  color: #fff;
}
.discordLogin .profilePic p {
  color: #fff;
  font-size: 30px;
}
.footer {
  bottom: 0px;
  left: 5%;
  right: 5%;
  margin: auto;
  width: 90%;
  margin: auto;
  padding: 30px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.footer p {
  color: #fff;
}
.footer a {
  color: #fff;
  text-decoration: none;
}
.footer a:hover {
  text-decoration: underline;
}

.footer ul {
  display: flex;
  align-items: center;
  list-style-type: none;
}
footer ul li {
  list-style-type: none;
}
.footer ul li:nth-child(1) {
  list-style-type: none;
  margin-right: 10px;
}
.footer ul li:nth-child(2) {
  list-style-type: none;
}

@media only screen and (max-width: 892px) {
  .verifyTop .imgRight,
  .verifyTop .imgLeft {
    display: none;
  }
  .discordLogin h1 {
    font-size: 4vw;
  }
}
@media only screen and (max-width: 600px) {
  .footer {
    flex-direction: column;
    justify-content: center;
  }
  .footer p {
    margin-bottom: 15px;
  }
}
